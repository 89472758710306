<template>
  <div>
    <div v-for="(types, category) in cats" :key="category">
      <h3 class="attribute-category">{{ categoryDescriptions[category] }}</h3>
      <table class="table table-bordered table-responsive">
        <tr v-for="(values, type) in filterTypes(types)" :key="type">
          <th class="text-nowrap">{{ type }}</th>
          <td valign="middle">
            <p v-for="( value, index) in values" :key="index" v-html="anchorme(value)">
              
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import anchorme from "anchorme";

  export default {
    name: 'AttributeList',
    data () {
      return {
        loading: false,
        cats: [],
        categoryDescriptions: {},
        typeDescriptions: {},
      }
    },
    props: {
      holder: String,
      productDetailsId: String,
    },
    methods: {
      anchorme (val) {
        return anchorme({
          input: val,
          options: {
            attributes: {
              target: "_blank",
            },
          }
        })
      },
      filterTypes (types) {
        let out = {}
        for (const [type, values] of Object.entries(types)) {
          if (type == 'Deliver to') continue;
          const typeInfo = this.$root.$data.fieldDefs[this.holder]['Attributes.' + type]
          if (typeInfo) {

            let showValues = []
            if (typeInfo.Options.length == 0) {
              showValues = values
            } else {
              showValues = values.map(val => {
                typeInfo.Options.forEach(option => {
                  if (val == option.JsonValue) {
                    val = option.Value
                  }
                })
                return val
              })
            }

            out[typeInfo.Title] = showValues
          }
        }
        return out
      }
    },
    mounted () {
      this.loading = true

      // Get AttributeCategory descriptions
      let catDescs = {}
      this.$root.$data.fieldDefs['NA']['Translations.AttributeCategory'].Options.forEach(o => {
        catDescs[o.JsonValue] = o.Value
      })
      this.categoryDescriptions = catDescs

      
      this.$http
        .get('products/'+this.productDetailsId+'/attributes')
        .then(response => {
            this.loading = false
            this.cats = response.data    
        }).catch(function () {
            this.roles = []
        })
    }
  }
</script>

<style scoped lang="scss">
  @import '@/assets/scss/variables';

  h3.attribute-category {
    //color: $color-dark-purple;
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
  }  
</style>